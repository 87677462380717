import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`

  @media ${device.tablet} {
    min-height: 410px;
  }

  @media ${device.desktopLG} {
    min-height: 500px;
  }

  @media ${device.desktopXL} {
    min-height: 648px;
  }
`

export const BannerMobile = styled.img`
  margin-bottom: 24px;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media ${device.tablet} {
    display: none;
  }
`

export const Title = styled.h1`
  font-family: "Sora", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${grayscale[500]};
  margin-bottom: 16px;

  @media ${device.desktopLG} {
    font-size: 32px;
    line-height: 40px;
  }

  @media ${device.desktopXL} {
    font-size: 40px;
    line-height: 50px;
    max-width: 457px;
  }
`

export const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[500]};
  margin-bottom: 32px;
  margin-right: -7px;

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;
    margin-right: -15px;
  }

  @media ${device.desktopXL} {
    margin-bottom: 40px;
    max-width: 470px;
  }
`

export const Link = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: ${orange.extra};
  padding: 15px 16px;
  border-radius: 8px;

  a {
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  @media ${device.desktopXL} {
    max-width: 457px;
  }
`
