import React from 'react'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ArrowRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-right'
import LeftRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-left'
import Icon from 'src/components/UI/MarkdownIcon'
import { orange } from 'src/styles/colors'
import * as URLs from '../../../../../../config/api/Urls'

import {
  Section,
  Title,
  Link,
  Card,
  Header,
  Body,
  Caroulsel,
} from './styles'

import { passos } from './_passos'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type PassosProps = {
  icon: string;
  title: string;
  description: string;
}

export const PedirPortabilidade = () => {
  const linkUrl = `${URLs.API_CONSIGNADO_NOVO}?origem=site&_ga=2.52934760.195875032.1647522679-1126716163.1643727907`
  const [ sendDatalayerEvent ] = useDataLayer()

  const rightArrow = ({ onClick }: never) => (
    <button
      onClick={onClick} data-home='carrossel-arrow' type='button'
      className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--right'
    >
      <ArrowRight width={40} height={40} color='orange--base' />
    </button>
  )

  const leftArrow = ({ onClick }: never) => (
    <button
      onClick={onClick} data-home='carrossel-arrow' type='button'
      className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--left'
    >
      <LeftRight width={40} height={40} color='orange--base' />
    </button>
  )

  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5'>
            <Title>
              Como pedir portabilidade de Empréstimo Consignado para o Inter?
            </Title>
            <Link className='d-none d-md-flex'>
              <a
                href={linkUrl}
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    element_name: 'Pedir portabilidade',
                    section_name: 'Como pedir portabilidade de Empréstimo Consignado para o Inter?',
                    redirect_url: linkUrl,
                  })
                }}
              >
                Pedir portabilidade
              </a>
            </Link>
          </div>
          <div className='col-12 col-md-6 inherit'>
            <Caroulsel>
              <DefaultCarousel
                sm={{ items: 1 }}
                md={{
                  items: 1,
                  partialVisibilityGutter: 10,
                }}
                lg={{
                  items: 1,
                  partialVisibilityGutter: 110,
                }}
                xl={{ items: 1, partialVisibilityGutter: 308 }}
              >
                {passos.map((passo: PassosProps, index: number) => (
                  <Card key={index}>
                    <Icon width='32' height='32' icon={`MD/${passo.icon}`} directory='orangeds' color={orange.extra} />
                    <Header>{passo.title}</Header>
                    <Body>{passo.description}</Body>
                  </Card>
                ),
                )}
              </DefaultCarousel>
            </Caroulsel>
            <Link className='d-md-none'>
              <a
                href={linkUrl}
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    element_name: 'Pedir portabilidade',
                    section_name: 'Como pedir portabilidade de Empréstimo Consignado para o Inter?',
                    redirect_url: linkUrl,
                  })
                }}
              >
                Pedir portabilidade
              </a>
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}
